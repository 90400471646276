import React, { Component } from 'react';
import AppRouter from './AppRouter.js';

import './App.css';

import { Container, Col, Navbar, Nav} from "react-bootstrap"

import thumper_icon from "./components/img/thumper-icon-clear.png"

export default class App extends Component {

  render() {
    return (
      <Container className="App" fluid>

        <Container style={{ overflow: 'hidden', height: '75px' }} fluid>

          <h1 style={{ fontFamily: "Modak", fontSize: 48, color: "#3B413C", marginBottom: -10, textAlign: 'left' }} className="justify-content-left">
            Th
            <span style={{ marginLeft: -1, paddingRight: 2 }}>
              <img src={thumper_icon} style={{ width: 30, height: 30 }} inline></img>
            </span>
            mper
          </h1>

          <h1 style={{ paddingLeft: 100, fontFamily: 'Modak', fontSize: 24, color: "#3B413C", textAlign: 'left' }}>Streams</h1>

        </Container>

        <hr style={{ margin: 0, padding: 0 }} />

        <AppRouter /> 

        <Navbar variant="app-nav-bottom" fixed="bottom" >
          <Nav className="mr-auto">
            <Nav.Link className="nav-link-app-nav-link-bottom" href="/">Home</Nav.Link>
            <Nav.Link className="nav-link-app-nav-link-bottom" href="/music">Spotify</Nav.Link>
            <Nav.Link className="nav-link-app-nav-link-bottom" href="/stream">Host</Nav.Link>
            <Nav.Link className="nav-link-app-nav-link-bottom" href="/radio">Radio</Nav.Link>
          </Nav>
          
          <a style={{ fontSize: 16, paddingRight: 8,color: "#3B413C" }} >2020</a>
          <img src={thumper_icon} style={{ width: 30, height: 30 }} ></img>
        </Navbar>        

      </Container>
    )
  }
}