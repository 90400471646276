import React from 'react';
import '../App.css';

// UI/UX Components

// + Bootstrap
import { Container } from "react-bootstrap"

export default function Home() {

  return (
    <Container>

      <h1 style={{
          fontFamily: 'Bangers',
          fontSize: 128,
          textShadow: '5px 5px white'
        }}
      >
        Simply No Sign-Up Streaming Service
      </h1>

      <p
        style={{
          fontFamily: 'Bangers',
          fontSize: 24,
          textAlign: 'right'
        }}
      > 
        It's pretty decent eh?
      </p>

      <p 
        id="warning" 
        style={{
          visibility: "hidden"
        }}
      > 
        *Enable chrome://flags/#enable-experimental-web-platform-features if you would like to stream.
      </p>

    </Container>
  )
}