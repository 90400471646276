import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom'
import './Radio.css';
import io from "socket.io-client";
import styled from "styled-components";

import { Button, Container, Row, Col, ListGroup, InputGroup, FormControl } from "react-bootstrap"

const wrtc = require('wrtc')
const Peer = require('simple-peer')

const Video = styled.video`
  margin: 0px;
  width: 100%;
  height: calc(100vh - 145px);
`;


export default function Radio() {
  const [yourID, setYourID] = useState("")
  const [users, setUsers] = useState({})
  const [relays, setRelays] = useState([])
  const [streamAccepted, setStreamAccepted] = useState(false)
  const [streamKey, setStreamKey] = useState("")
  const [streamMessage, setStreamMessage] = useState("")

  const streamMedia = useRef()
  const socket = useRef()
  const streamChat = useRef([])

  const SERVER_URL = 'https://streamer-socket-io.herokuapp.com/' 
  //const SERVER_URL = 'http://192.168.2.5:8000/'

  useEffect(() => {

    socket.current = io.connect(SERVER_URL)
    streamChat.current = []
    
    socket.current.on("yourID", (id) => {
      console.log("id", id)
      setYourID(id)
    }) 

    socket.current.on("allUsers", (users) => {
      console.log('users', users)
      setUsers(users)
    })
    
    socket.current.on("allRelays", (relays) => {
      console.log("relays", relays)
      setRelays(relays)
    })

    socket.current.on('emitNeighbor', (neighborMessage) => {
      console.log(neighborMessage)

      updateChat(neighborMessage)

      ReactDOM.render(
        <>
          { streamChat.current.map(m => chatMessage(m)) }
        </>
      , document.getElementById('chatRoom'))

      console.log(streamChat.current)

    })
    
  }, [streamChat])

  let updateChat = (m) => {
    streamChat.current.push(m)
  }

  function connect(key) {
    console.log(key, 'doo doo')
    console.log('doo doo')

    const peer = new Peer({
      initiator: true,
      trickle: true,
      wrtc: wrtc
    })

    peer.on("signal", data => {
      console.log('client signaling')

      socket.current.emit('client_radio_signal', { signalData: data, from: yourID,  streamKey: key })
    })

    peer.on("stream", stream => {
      if (streamMedia.current) {
        streamMedia.current.srcObject = stream
      }

      console.log(stream)
    })

    socket.current.on('success_client_radio_signal', signal => {
      console.log('success_client_signal', signal)

      setStreamAccepted(true)
      setStreamKey(key)
 
      peer.signal(signal)
    })

    // error handling
    
    peer.on("error", (err) => {
      console.log(err)
      peer.destroy([err])
    })
    

  }

  // Component for Chat Message
  let chatMessage = (m) => {
    return ( 
      <Row className="justify-content-md-start" style={{ backgroundColor: 'white', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        <p style={{ fontSize: 16, paddingLeft: 5, marginBottom: 0 }}>{m}</p>
      </Row>
    )
  }

  // Component for Stream Video
  let SelectedStream;
  if (streamAccepted) {
    SelectedStream = (
      <Video playsInline ref={ streamMedia } autoPlay />
    );
  }

  return (
    <Container className="App-container" style={{ overflowY: 'hidden', margin: 0, padding: 0 }} fluid>

      <Row style={{ margin: 0, padding: 0 }}>

        { streamAccepted ?
        <Col className="radio-main-col-watch">
          { SelectedStream }
        </Col>
        :
        <Col className="radio-main-col-menu">

          <Container fluid>
            { streamAccepted ? null :
              <ListGroup className="available-streams">
              { // Map Available Streams
                (relays).map(key => {
                  if(streamKey != key[0])
                    return (
                      <ListGroup.Item action onClick={() => {
                        connect(key[0]) 
                      }}>
                        Connect {key[1]}
                      </ListGroup.Item>
                    )
                })
              }
              </ListGroup> 
            }

            { streamAccepted ?
              <h6 style={{ margin: 0, padding: 0, color: 'red' }}>Refresh Page to Load New Stream...</h6>
            :
              <h6 style={{ margin: 0, padding: 0, color: 'green' }}>Connect to a Stream!</h6>
            }
          </Container>
        </Col>
        }

        { streamAccepted ?
        <Col className="radio-side-col" md={3} fluid>

          <div>

            <Container id="chatRoom" className="justify-content-md-start" >
              { /* ReactDOM will render messages in here! */ } 
            </Container>

            <InputGroup className="mb-3">
              <FormControl
                value={ streamMessage }
                onChange={e => setStreamMessage(e.target.value) }
                placeholder="Message"
                aria-describedby="basic-addon2"
              />
            </InputGroup>

            <Row className="justify-content-md-end" style={{ marginTop: -10 }} >
              <Button variant="outline-secondary" 
                onClick={() => { 
                  socket.current.emit('emitMessage', streamKey, yourID + ": " + streamMessage, yourID)

                  streamChat.current.push("me:  " + streamMessage)

                  ReactDOM.render(
                    <>
                      { streamChat.current.map(m => chatMessage(m)) }
                    </>
                  , document.getElementById('chatRoom'))
                  
                  setStreamMessage("")
                }} 
              >
                Send
              </Button>
            </Row>

          </div>
        </Col>
        : null }

      </Row>

    </Container>
  );
}