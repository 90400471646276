import React from "react";
import {
  BrowserRouter,
  Switch,
  Route
} from "react-router-dom";

import Music from "./components/Music.js";
import Stream from "./components/Stream.js"
import Radio from "./components/Radio.js";
import Callback from "./components/Callback.js";
import Home from "./components/Home.js"

export default function AppRouter() {
  return (
    <BrowserRouter>
        <Switch>
          <Route path="/music" component={(props) => <Music {...props} />} />
          <Route path="/callback" component={(props) => <Callback {...props} />} />
          <Route path="/stream" component={(props) => <Stream {...props} />} />
          <Route path="/radio" component={(props) => <Radio {...props} />} />

          <Route path="/" component={(props) => <Home {...props} />} />
        </Switch>
    </BrowserRouter>
  )
}