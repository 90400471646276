import React, { useEffect, useState, useRef } from 'react'
import '../App.css'
import io from "socket.io-client"
import Peer from "simple-peer"
import styled from "styled-components"

import { InputGroup, Form, FormControl, Button, Container, Row, Col, Popover, OverlayTrigger } from "react-bootstrap"

const Video = styled.video
`
  margin: 0px;
  padding: 0px;
  height: calc(100vh - 145px);
  width: 100%
`

export default function Stream() {


  const [yourID, setYourID] = useState("");
  const [users, setUsers] = useState({});

  const [stream, setStream] = useState();
  const [streamName, setStreamName] = useState("Stream's Name")
  const [callAccepted, setCallAccepted] = useState(false);

  const [resolution, setResolution] = useState([1280, 720])
  const [frameRate, setFrameRate] = useState(60)  

  const userVideo = useRef()
  const socket = useRef()
  const peer = useRef()

  const SERVER_URL = 'https://streamer-socket-io.herokuapp.com/' 
  //const SERVER_URL = 'http://192.168.2.5:8000/'
  
  useEffect(() => {
    // url to server
    socket.current = io.connect(SERVER_URL)
    
    console.log(navigator.mediaDevices.getSupportedConstraints())

    getLocalDisplayMedia()

    socket.current.on("yourID", (id) => {
      setYourID(id);
    })

    socket.current.on("allUsers", (users) => {
      setUsers(users);
    })

  }, [])

  function getLocalDisplayMedia() {

    navigator.mediaDevices.getDisplayMedia({ 

      video: {

        //aspectRatio: resolution[0] / resolution[1],
        frameRate: frameRate,
        //width: resolution[0],
        //height: resolution[1]

      }, 

      audio: { 

        volume: 1.0, 
        latency: 0.0,
        sampleSize: Math.pow(2, 16), // 16-Bit Digital Audio
        sampleRate: 44100, // CD Quality 
        autoGainControl: false, 
        googAutoGainControl: false, 
        noiseSuppression: false, 
        echoCancelation: false 

      } 

    }).then( stream => {

      setStream(stream)

      if (userVideo.current) {
        userVideo.current.srcObject = stream;
      }

    })

  }

  function directStream() {

    peer.current = new Peer({
      initiator: true,
      trickle: false,
      stream: stream,
    })

    peer.current.on("signal", data => {
      socket.current.emit("directStream", { signalData: data, from: yourID, name: streamName })
    })

    peer.current.on("stream", stream => {
      console.log(stream)
    })


    socket.current.on("successDirectStream", signal => {
      console.log('Stream Successfully Directed', signal)

      setCallAccepted(true)
      peer.current.signal(signal)
    })

    // error handling
    peer.current.on("error", (err) => {
      peer.current.destroy([err])
    })

  }

  let UserVideo
  if(stream) {
    UserVideo = (
      <Video playsInline muted ref={userVideo} autoPlay />
    )
  }

  const popover = (

    <Popover id="popover-basic">

      <Popover.Title as="h6">Stream Settings</Popover.Title>

      <Popover.Content>

        <Container fluid>

          <Row>

            <Col>

              <Form>
                <Form.Group controlId="res">
                  <Form.Label>Resolution</Form.Label>
                  <Form.Control 
                    as="select" 
                    onChange={e => setResolution(e.target.value)}
                    custom
                  >
                    <option value={ [3840, 2160] }>4k</option>
                    <option value={ [2160, 1440] }>1440p</option>
                    <option value={ [1920, 1080] }>1080p</option>
                    <option value={ [1280, 720] }>720p</option>
                    <option value={ [858, 480] }>480p</option>
                    <option value={ [480, 360] }>360p</option>
                    <option value={ [2160, 240] }>240p</option>
                  </Form.Control>
                </Form.Group>
              </Form>

            </Col>

          </Row>

          <Row>

            <Col>

              <Form>
                <Form.Group controlId="fps">
                  <Form.Label>Frame Rate</Form.Label>
                  <Form.Control 
                    as="select"  
                    onChange={e => setFrameRate(e.target.value)}
                    custom
                  >
                    <option>60</option>
                    <option>30</option>
                    <option>24</option>
                  </Form.Control>
                </Form.Group>
              </Form>
              
            </Col>

          </Row>

          <Row>

            <Col>
            
              <Button
                onClick={() => getLocalDisplayMedia()}
              >
                Save
              </Button>

            </Col>

          </Row>

        </Container>

      </Popover.Content>

    </Popover>

  )

  return (
    <Container className="App-container" style={{ overflowY: 'hidden', backgroundColor: 'white', margin: 0, padding: 0 }} fluid>

      <Row style={{ margin: 0, padding: 0 }}>

        <Col  style={{ margin: 0, padding: 0 }}>

          <Container style={{ backgroundColor: '#3B413C', margin: 0, padding: 0 }} fluid>
            {UserVideo}            
          </Container>

        </Col>

        <Col sm={3} style={{ margin: 0, padding: 0 }}>

          <Row className="justify-content-sm-center" style={{ margin: 5 }}>
            { callAccepted ?
              <h6 style={{ color: 'green' }}>Stream is Now Live!</h6>
            :
              <h6 style={{ color: 'red' }}>Stream is Not Live...</h6>
            }
          </Row>
          

          <Container fluid>

            <Row>

              <InputGroup className="mb-3">
            
                <FormControl
                  placeholder="Stream's Name"
                  aria-label="Stream's Name"
                  aria-describedby="basic-addon2"
                  onChange={ e => setStreamName(e.target.value) }
                />

              </InputGroup>

            </Row>

            <Row>
              { !callAccepted ?
                <Button onClick={() => directStream()}>Host Stream</Button>
              :
                null
              }

              {/* <OverlayTrigger trigger="click" placement="left" overlay={popover}>
                <Button variant="success" disabled>Settings</Button>
              </OverlayTrigger> */}

            </Row>

          </Container>

        </Col>
      
      </Row>

    </Container>
  );
} 