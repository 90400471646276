import React, { useEffect } from "react"

// Query String
let query_string = require('query-string')

// Environment Constants
const client_id = process.env.REACT_APP_CLIENT_ID 
const client_secret = process.env.REACT_APP_CLIENT_SECRET 
const redirect_uri = process.env.REACT_APP_REDIRECT_URI

export default function Callback(props) {
    
    useEffect(() => {
        const cb = window.location.href
        const cb_obj = query_string.parseUrl(cb)
        const cb_code = cb_obj.query.code

        queryCallback(cb_code)
        .then(res => {
            props.history.push({
                pathname: "/music",
                state: res
            })
    
            window.location.reload(false)
        })
        .catch(err => console.log('Error Authenticating Spotify: ', err))
    })

    // + Query of New Access Token (2)
    let queryCallback = async (code) => {
        let req = await fetch("https://accounts.spotify.com/api/token", {
            "credentials": "include",
            "headers": {
                "accept": "application/json",
                "accept-language": "en-US,en;q=0.9",
                "authorization": `Basic ${ Buffer(client_id + ':' + client_secret).toString('base64') }`,
                "content-type": "application/x-www-form-urlencoded"
            },
            "body": `grant_type=authorization_code&code=${ code }&redirect_uri=${ redirect_uri }`,
            "method": "POST",
            "mode": "cors"
        })
        let res = await req.json()

        return res
    }

    return <p>Callback</p>
}