// React Components
import React, { useEffect, useState, useRef } from "react"
import ReactDOM from "react-dom"
import { useCookies } from "react-cookie"

// UI/UX Components
import "./Music.css"

// + Bootstrap
import { Container, Button, Form, ListGroup, Row, Col, Image, InputGroup, FormControl, Tab, Tabs, Dropdown, DropdownButton } from "react-bootstrap"

// + FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlay, faPause, faForward, faBackward } from "@fortawesome/free-solid-svg-icons"

// Requirements

// + Load Scripts Dynamically
let load = require('load-script')

// Query String
let querystring = require('querystring')

// Spotify Web API Wrapper
let SpotifyWebAPI = require('spotify-web-api-js')
let spotify = new SpotifyWebAPI()

export default function Music(props) {

    // Environment Constants
    const client_id = process.env.REACT_APP_CLIENT_ID 
    const client_secret = process.env.REACT_APP_CLIENT_SECRET 
    const redirect_uri = process.env.REACT_APP_REDIRECT_URI

    // Cookies
    const [cookie, setCookie] = useCookies()

    // State
    const [accessToken, setAccessToken] = useState(cookie.accessToken)
    const [refreshToken, setRefreshToken] = useState(cookie.refreshToken)
    const [player, setPlayer] = useState(null)
    const [paused, setPaused] = useState(true)
    const [progress, setProgress] = useState(50)
    const [playlists, setPlaylists] = useState([])
    const [searchQuery, setSearchQuery] = useState("")
    const [searchResults, setSearchResults] = useState([])
    const [mainPage, setMainPage] = useState([])
    const [prevPage, setPrevPage] = useState([])
    const [nextPage, setNextPage] = useState([])
    const [playerPosition, setPlayerPosition] = useState(100)
    const [currentSongDuration, setCurrentSongDuration] = useState(0)

    const interval = useRef()

    // Component Mount/Dismount
    useEffect( () => { 
        console.log(props)
        console.log(client_id)

        if(typeof props.location.state != 'undefined') {
            if('access_token' in props.location.state) {
                setAccessToken(props.location.state.access_token)
                setCookie('accessToken', props.location.state.access_token, {
                    path: '/music',
                    sameSite: "lax",
                    secure: false
                })
            }
            if('refresh_token' in props.location.state) {
                setRefreshToken(props.location.state.refresh_token)
                setCookie('refreshToken', props.location.state.refresh_token, {
                    path: '/music',
                    sameSite: "lax",
                    secure: false
                })
            }

        }
        props.location.state = {}

        if(cookie.refreshToken) {
            queryRefreshToken()
            .then(res => {
                setAccessToken(res.access_token)
                setCookie('accessToken', res.access_token, {
                    path: '/music',
                    sameSite: 'none'
                })
                spotify.setAccessToken(res.access_token)

                getPlaylists(res.access_token).then( res => { console.log(res); setPlaylists(res.items) }  )
                load('https://sdk.scdn.co/spotify-player.js', handleScriptLoad(res.access_token))
            })
            .catch(err => console.log(err))
        }

        return () => clearInterval(interval.current)
    }, [])


    // Authentication Methods

    /**
     * Generates a random string containing numbers and letters
     * @param  {number} length The length of the string
     * @return {string} The generated string
     */
    let generateRandomString = (length) => {
        let text = ''
        let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    
        for (let i = 0; i < length; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length))
        }

        return text
    }

    // + Login Query (1)
    let queryLogin = () => {
        var state = generateRandomString(16)
        // res.cookie(stateKey, state)
      
        // your application requests authorization
        var scope = 'streaming user-read-email user-read-private user-read-playback-state'
        window.open('https://accounts.spotify.com/authorize?' +
          querystring.stringify({
            response_type: 'code',
            client_id: client_id,
            scope: scope,
            redirect_uri: redirect_uri,
            state: state
          }),
          "_self"
        )
    }

    // + Callback Query (2) In Callback.js

    // + Spotify Web API Query Methods (3) 

    // + Access Token Query (4)
    let queryRefreshToken = async () => {
        let req = await fetch("https://accounts.spotify.com/api/token", {
            "credentials": "include",
            "headers": {
                "accept": "application/json",
                "authorization": `Basic ${ Buffer(client_id + ':' + client_secret).toString('base64') }`,
                "content-type": "application/x-www-form-urlencoded"
            },
            "body": `grant_type=refresh_token&refresh_token=${ refreshToken }`,
            "method": "POST",
            "mode": "cors"
        })
        let res = await req.json()

        return res
    }

    // --- End of Authentication Methods


    // Web Playback SDK Initiation Methods 
    
    // + Load Spotify Web Playback SDK After Loading spotify-script.js
    let handleScriptLoad = (a_token) => {

        // Initiate Player with Handlers
        window.onSpotifyWebPlaybackSDKReady = () => {
            // Set Player Options
            let options = {
                name: 'Thumper Streams',
                getOAuthToken: callback => callback(a_token),
                volume: 1
            }

            // Create Player Object
            let p = new window.Spotify.Player(options)

            // Error handling
            p.addListener('initialization_error', ({ message }) => { 
                console.error(message) 
            })
            p.addListener('authentication_error', ({ message }) => { 
                console.error(message) 
            })
            p.addListener('account_error', ({ message }) => { 
                console.error(message) 
            })
            p.addListener('playback_error', ({ message }) => { 
                console.error(message)
            })

            // Playback status updates
            p.addListener('player_state_changed', state => { 
                console.log("Update on Player State")
                console.log(state)

                document.getElementById('songName').innerHTML = state ? state.track_window.current_track.name : 'No Song Playing!'
                document.getElementById('songArtist').innerHTML = state ? state.track_window.current_track.artists[0].name : 'No Artist Playing!'
                document.getElementById('songCover').src = state ? state.track_window.current_track.album.images[0].url : ''

                state ? setPaused(state.paused) : setPaused(true)

                clearInterval(interval.current) 

                state.paused ? clearInterval(interval.current) : interval.current = setInterval(() => setPlayerPosition(p => p + 250), 250)

                setCurrentSongDuration(state.duration)
                setPlayerPosition(state.position)
            })

            // Ready
            p.addListener('ready', ({ device_id }) => {
                console.log('Ready with Device ID', device_id)
                transfer(device_id, a_token)
            })

            // Not Ready
            p.addListener('not_ready', ({ device_id }) => {
                console.log('Device ID has gone offline', device_id)
            })

            // Connect Player
            p.connect().then(success => {
                if (success) console.log('The Web Playback SDK successfully connected to Spotify!')
                else console.log('Failed to connect to Spotify!')
            })

            // Set Player State
            setPlayer(p)            
        }

    }

    // Transfer Playback From Whatever Device To The Web Playback SDK
    let transfer = async (id, tok) => {
        await fetch(`https://api.spotify.com/v1/me/player`, {
            method: 'PUT',
            body: `{"device_ids":["${ id }"]}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${ tok }`
            }
        })
    }

    // Player Initiation Error: let handleScriptError = () => { console.log('Error Initiating Spotify Web Playback SDK') }

    // --- End of Web Playback SDK Initiation Methods 

    // Player Methods
    
    // + Play/Pause
    let playback = () => {
        player.togglePlay()
        .then(() => {
            console.log('Toggled playback!');
        })
    }

    // + Set Volume
    const volume = () => {	
        const newVolume = document.getElementById('volumeSlider').value / 100;
        console.log(newVolume)
        player.setVolume(newVolume).then(() => {
            console.log('Volume updated!');
        });
    };

    // + Play Previous Song
    const previous = () => {	   	
        player.previousTrack().then(() => {
            console.log('Set to previous track!');
        });
    };

    // + Play Next Song
    const next = () => {	   	
        player.nextTrack().then(() => {
            console.log('Skipped to next track!');
        })
    }

    // + Disconnect
    const disconnect = () => {	   	
        player.disconnect().then(success => {
            if(success) console.log('Successfully disconnected from Spotify!')
            else console.log('Failed to disconnect from Spotify!')
        })
    }

    // + Connect
    const connect = () => {	   	
        player.connect().then(success => {
            if (success) console.log('The Web Playback SDK successfully connected to Spotify!')
            else console.log('Failed to connect to Spotify!')
        })
    }

    // + Get Current State
    const getCurrentState = () => {
        player.getCurrentState().then(state => {
            if (!state) {
              console.error('User is not playing music through the Web Playback SDK');
              return;
            }
          
            let {
                current_track,
                next_tracks
            } = state.track_window;
            let position = state.position
            console.log(state)
          
            console.log('Currently Playing', current_track);
            console.log('Playing Next', next_tracks);
            console.log('Position', position)
            setPlayerPosition(position)
        })
    }

    // --- End of Player Methods


    // Spotify Web API Methods

    let play = async (track_uri) => {
        let res = await fetch(`https://api.spotify.com/v1/me/player/play?device_id=${player._options.id}`, {
            method: 'PUT',
            body: `{ "uris" : ["${track_uri}"] }`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        })
        return res
    }

    let queue = async (track_uri) => {
        let res = await fetch(`https://api.spotify.com/v1/me/player/queue?uri=${track_uri}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        })
        return res
    }

    let search = async (track_uri) => {
        let res = await fetch(`https://api.spotify.com/v1/search`, {
            method: 'GET',
            body: `{ "uris" : ["${track_uri}"] }`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        })
        let res_json = await res.json()

        return res_json
    }

    let getPlaylists = async (tok) => {
        let res = await fetch(`https://api.spotify.com/v1/me/playlists`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tok}`
            }
        })
        let res_json = await res.json()

        return res_json
    }

    let getUserProfile = async () => {
        let res = await fetch(`https://api.spotify.com/v1/me`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        })
        let res_json = await res.json()
        
        return res_json
    }

    let getPlaylist = async (id) => {
        let res = await fetch(`https://api.spotify.com/v1/playlists/${id}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        })
        let res_json = await res.json()
        
        return res_json
    }

    let getPlaylistTracks = async (id) => {
        let res = await fetch(`https://api.spotify.com/v1/playlists/${id}/tracks`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        })
        let res_json = await res.json()
        
        return res_json
    }

    // -- End of Spotify Web API Methods

    return (
        <Container className="App-container" style={{ overflowY: 'hidden', margin: 0 }} fluid>

            { player ?
                null
            :
                <Container style={{ height: 'calc(100vh - 400px)' }} fluid>
                    <Button 
                        variant="success"
                        onClick={ () => queryLogin() }
                    >
                        Login!
                    </Button>
                </Container>
            }

            { player ?
            <Container className="main-container" fluid>

                <Container className="no-margin-padding" fluid>

                    <Row className="no-margin-padding">

                        <Col md={3} className="no-margin-padding">

                            <Container className="no-margin-padding" style={{ height: "calc(100vh - 300px)" }} fluid>
                                
                                <h6 style={{ marginTop: 5 }}>Playlists</h6>

                                <ListGroup id="playlist" className="playlist" >
                                { 
                                    playlists.map(playlist => { 
                                        return (
                                            <ListGroup.Item 
                                                as='a'
                                                onClick={() => {
                                                    console.log(playlist.id, playlist.uri)
                                                    getPlaylistTracks(playlist.id)
                                                    .then(res => { 
                                                        console.log(res)
                                                        let arr = (res.items).map(item => item.track)
                                                        setSearchResults(arr)
                                                    })
                                                }}    
                                            >
                                                {playlist.name}
                                            </ListGroup.Item> 
                                        )
                                    })
                                }
                                </ListGroup>

                            </Container>

                        </Col>

                        <Col className="no-margin-padding" style={{ marginLeft: 15}} >

                            <Container className="no-margin-padding" style={{ height: "calc(100vh - 300px)" }} fluid>

                                <Row className="no-margin-padding"> 

                                    <Col md={5} className="no-margin-padding">

                                        <Row className="no-margin-padding">

                                            <InputGroup className="mb-3">
                                                <FormControl
                                                    placeholder="..."
                                                    style={{  }}
                                                    aria-label="Search"
                                                    aria-describedby="basic-addon2"
                                                    onChange={ e => setSearchQuery(e.target.value) }
                                                />

                                                <InputGroup.Append >
                                                    <Button
                                                        onClick={() => { 
                                                            spotify.searchTracks(searchQuery, { limit: 50 })
                                                            .then(res => {
                                                                console.log(res)
                                                                setSearchResults(res['tracks']['items'])
                                                            }) 
                                                        }}
                                                        style={{ marginTop: -10 }}
                                                    >
                                                        Search
                                                    </Button>                        
                                                </InputGroup.Append>
                                            </InputGroup>
                                            
                                        </Row>

                                    </Col>

                                    <Col className="no-margin-padding">
                                        
                                        <Row className="no-margin-padding justify-content-end">

                                            <Button
                                                variant="success"
                                                style={{ marginRight: -5 }}
                                                onClick={() => setPlayer(null)}
                                            > 
                                                Log Out
                                            </Button>

                                        </Row>

                                    </Col>

                                </Row>

                                <Row className="no-margin-padding">

                                    <Col className="no-margin-padding">

                                        <ListGroup id="results" className="results" style={{ zIndex: -1 }}>
                                        { 
                                            searchResults.map(item => { 

                                                return (
                                                    <ListGroup.Item as='a' style={{ height: 80 }} 
                                                        onDoubleClick={() => play(item.uri)} 
                                                        onContextMenu={event => {
                                                            console.log(event.pageX, event.pageY)
                                                        }}
                                                    >

                                                        <Row>

                                                            <Col>
                                                                <Image style={{ marginTop: -25  }} width="64px" src={ item.album.images[2].url } thumbnail inline />
                                                            </Col>

                                                            <Col>
                                                                {item.name}
                                                            </Col>

                                                            <Col>
                                                                <DropdownButton id="dropdown-basic-button" title="Options" style={{ marginTop: -15 }} >
                                                                    <Dropdown.Item onClick={() => queue(item.uri)}>Add to Queue</Dropdown.Item>
                                                                </DropdownButton>
                                                            </Col>

                                                        </Row>

                                                    </ListGroup.Item> 
                                                )
                                            }) 
                                        }
                                        </ListGroup>

                                    </Col>

                                </Row>

                            </Container>

                        </Col>         

                    </Row>

                </Container>

                <Container className="no-margin-padding" fluid>

                    <Row>

                        <Col sm={3} className="align-self-center" fluid>

                            <Row fluid>

                                <Col fluid>

                                    <Image id="songCover" style={{ height: '64px', width: '64px' }} thumbnail inline />  

                                </Col>
                                
                                <Col className="align-self-center" fluid>

                                    <h5 id="songName" inline>-</h5>
                                    <h6 id="songArtist" >-</h6>

                                </Col>

                            </Row>
                            
                        </Col>
                        
                        <Col sm={7} className="align-self-center">

                            <Row className="no-margin-padding">

                                <Col className="no-margin-padding align-self-end" style={{ marginTop: 20 }}>

                                    <Button
                                        onClick={ () => previous() }
                                    >
                                        <FontAwesomeIcon icon={ faBackward } />
                                    </Button>

                                    <Button 
                                        onClick={ () => playback() } 
                                    > 
                                        { paused ? <FontAwesomeIcon icon={ faPlay } /> : <FontAwesomeIcon icon={ faPause } /> }
                                    </Button>

                                    <Button
                                        onClick={ () => next() }
                                    >
                                        <FontAwesomeIcon icon={ faForward } />
                                    </Button>

                                </Col>

                            </Row>
                            
                            <Row className="no-margin-padding">

                                <Col>

                                    <Form.Control 
                                        type="range" 
                                        min="0" 
                                        max={currentSongDuration} 
                                        value={playerPosition}
                                        onMouseUp={ e => {  
                                            spotify.seek(e.target.value, { device_id: player._options.id }).then(res => console.log(res))
                                        }} 
                                        onChange={ e => { 
                                            clearInterval(interval.current)
                                            setPlayerPosition(e.target.value) 
                                        }} 
                                        id="seekSlider" 
                                        custom
                                    />  

                                </Col>     

                            </Row>

                        </Col>

                        <Col className="align-self-center">

                            <h6>Volume</h6>

                            <Form.Control 
                                type="range" 
                                min="0" 
                                max="100" 
                                defaultValue="100"
                                onChange={ () => volume() } 
                                id="volumeSlider" 
                                custom
                            />

                        </Col>

                    </Row>

                </Container>

            </Container>
            : null }

            <hr style={{ margin: 0, padding: 0 }}/>

            { /*
            <Tabs defaultActiveKey="info" transition={false} id="noanim-tab-example">
                <Tab eventKey="info" title="Info">
                    <h1>debugging buttons</h1>
                </Tab>

                <Tab eventKey="debug" title="Debug">
                    <button
                        onClick={ () => queryLogin() }
                    >
                        Login (1)
                    </button>

                    <button
                        onClick={ () => {
                            queryRefreshToken()
                            .then(res => {
                                setAccessToken(res.access_token)
                                setCookie('accessToken', res.access_token, {
                                    path: '/music',
                                    sameSite: 'none'
                                })
                                spotify.setAccessToken(res.access_token)
                            })
                            .catch(err => console.log(err))
                        }}
                    >
                        Get New Access Token (4)
                    </button>

                    <button
                        onClick={() => {
                            console.log(accessToken)
                        }}
                    >
                        Print Access Token
                    </button>

                    <button
                        onClick={() => {
                            console.log(refreshToken)
                        }}
                    >
                        Print Refresh Token
                    </button>

                    <button
                        onClick={() => {
                            disconnect()
                        }}
                    >
                        Disconnect Spotify Player
                    </button>

                    <button
                        onClick={() => {
                            connect()
                        }}
                    >
                        Connect Spotify Player
                    </button>

                    <button
                        onClick={() => {
                            console.log(player)
                        }}
                    >
                        Print Spotify Player Object
                    </button>

                    <button
                        onClick={() => play("spotify:track:1oKQXGq2QgQm2IxOEf83pA").then( mes => console.log(mes) )}
                    >
                        Play Some Song
                    </button>

                    <button
                        onClick={() => {
                            let f = document.getElementsByTagName('iframe')[0]
                            f.remove()
                        }}
                    >
                        Remove iFrame
                    </button>

                    <button
                        onClick={() => {
                            getPlaylists().then( res => { console.log(res); setPlaylists(res.items) }  )
                            load('https://sdk.scdn.co/spotify-player.js', handleScriptLoad(accessToken))
                        }}
                    >
                        Load Spotify-Player Script
                    </button>

                    <button
                        onClick={() => { 
                            getCurrentState() 
                        }}
                    >
                        Get Current State
                    </button>

                    <button
                        onClick={() => { 
                            getPlaylists().then( res => { console.log(res); setPlaylists(res.items) }  ) 
                        }}
                    >
                        Playlists
                    </button>

                    <button
                        onClick={() => { 
                            getUserProfile().then( res => console.log(res) ) 
                        }}
                    >
                        User Profile
                    </button>


                </Tab>
            </Tabs>
            */ }
                
        </Container>
    );

}